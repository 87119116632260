import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import Lights from "./components/Lights.js";
import _, { delay } from "lodash";
import * as THREE from "three";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { EffectComposer, Bloom, Glitch } from "@react-three/postprocessing";
import CameraControls, {
  useCameraControls,
} from "./components/CameraControls.js";
import { create as createZustand } from "zustand";
import { useRace } from "./components/Race.js";
import Track from "./track/track.js";
import { Perf } from "r3f-perf";
import { RaceRunner, useRaceRunContext } from "./components/RaceRunner.js";
import { useControls } from "leva";
import Podium from "./components/Podium.js";
import { useState } from "react";
import { getv, jstr, nils } from "./utils/utils.js";
import { Environment, EnvironmentMap } from "@react-three/drei";
import TronBike from "./components/TronBike.js";
import { degToRad } from "three/src/math/MathUtils.js";
import { useAppContext } from "./App.js";
import { Effects } from "./wrappers/Effects.js";
import { useModelStoreCt } from "./components/ModelsStore.js";
import LoadingScreen from "./components/LoadingScreen.js";

export const useExperience = createZustand((set) => ({}));
const glitob = {
  stopped: [false, 0],
  running: [1, 0.5],
  replay: [2, 1],
  podium: [2, 0.5],
};

function Experience() {
  const r3fstate = useThree();
  const econ = {};

  const exp = useExperience();
  const camct = useCameraControls();

  const appcon = useAppContext();
  const test = appcon.test;

  const skybox = 2;

  const envmapfiles = [
    `/images/cubemaps/skybox (${skybox})/right.png`,
    `/images/cubemaps/skybox (${skybox})/left.png`,
    `/images/cubemaps/skybox (${skybox})/top.png`,
    `/images/cubemaps/skybox (${skybox})/bottom.png`,
    `/images/cubemaps/skybox (${skybox})/front.png`,
    `/images/cubemaps/skybox (${skybox})/back.png`,
  ];

  const modelstore_ct = useModelStoreCt();

  const racect = useRace();
  const rruncon = useRaceRunContext();
  const { runmode } = rruncon;

  const dist = useMemo(() => {
    if (!racect.loaded) return 100;

    let cb = getv(racect, "race.cb");
    return cb * 100;
  }, [racect.loaded, test]);

  const [applyglitch, set_applyglitch] = useState(false);
  useEffect(() => {
    console.log({ runmode });
    if (!nils(glitob[runmode])) {
      let [str, dur] = glitob[runmode];
      if (!str) return;
      set_applyglitch(str);
      setTimeout(() => {
        set_applyglitch(false);
      }, dur * 1e3);
    }
  }, [runmode]);

  const loads = [racect.loaded, modelstore_ct.loaded, camct.loaded];
  useEffect(() => {
    let loaded = _.reduce(loads, (acc, v) => acc && v, true);
    // if (loaded == true) rruncon.set_init(true);
  }, [jstr(loads)]);

  return (
    <>
      {/* <Perf position="top-left" /> */}

      <color args={["#000B14"]} attach={"background"} />

      <Effects {...{ applyglitch }} />
      <CameraControls />

      <Lights />
      <ambientLight intensity={0.3} />

      {modelstore_ct.loaded === true && (
        <>
          {["running", "stopped", "replay"].includes(runmode) && (
            <>
              <RaceRunner />
              <Track dist={dist} />
            </>
          )}

          {["podium"].includes(runmode) && (
            <>
              <Podium />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Experience;
